.bgvbody{
    background: #f1f1f1;
	font-family: 'Lato', sans-serif;
	font-size: 15px;
	line-height: 1.8;
	color: rgba(0,0,0,.5);
}

.line_rating {
    border: 2px solid;
    border-image-slice: 1;
    border-bottom: 5px;
    border-image-source: linear-gradient(
270deg,#eb7c45,#2a3c81);
}

.bgvemail-container{
	background: #f1f1f1;
	font-family: 'Lato', sans-serif;
	font-size: 15px;
	line-height: 1.8;
	color: rgba(0,0,0,.5);
	border-left: 10px solid;
    border-right: 10px solid;
	border: 10px solid;
    border-image-slice: 1;
    border-width: 3px;
	background-repeat: no-repeat;
    border-image-source: linear-gradient(to right,  rgb(255, 0, 87), rgb(230 26 115), rgb(6 164 140));

}
.bgvmidtext{
	text-align: justify;
	padding:2em;
	padding-top:0em;
	color: black;
	font-weight: bold;
}
.bgvmiddletext{
	text-align: justify;
	padding:3em;
	text-align: center;
	color: #2a3c81;
}
.bgvinput{
	padding:2.5em;
	text-align:justify;
	font-size: medium;
}
.sigCanvas{
	/* background-color: red; */
}


.watermarked{
	width: 300px;
	position: absolute;
	top: 60%;
	left: 38%;
	background-image: "images/enLogo.png";
	opacity: 0.1;
  }