/* body{
    font-family: 'poppins',sans-serif;
} */
a {
  text-decoration: none;
}

ul {
  padding: 0;
  margin: 0;
}

ul li {
  list-style: none;
}

img {
  max-width: 100%;
}

.mainHeader {
  padding: 10px 13px;
  background: #ffffff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.10);
  z-index: 9;
  position: relative;
}

.userleft {
  width: 400px;
}

.chatRight {
  width: calc(100% - 400px);
  border-left: 1px solid #eee;
}


.profileMain {
  display: flex;
  align-items: center;
  gap: 10px;
}

.profile-image {
  width: 40px;
  height: 40px;
  margin: 0;
  display: inline-block;
}

.profile-image img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}

.profileName strong {
  display: block;
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.profileName span {
  display: block;
  font-size: 13px;
  font-weight: 500;
}

.userSearch {
  width: 40%;
}

.userSearch .form-control {
  border-radius: 10px;
  font-size: 14px;
  height: 40px;
  padding: 0 0 3px 45px;
  outline: none;
  box-shadow: none;
  /* background-image: url(../img/search-icon.svg); */
  background-size: 18px;
  background-position: center left 13px;
  background-repeat: no-repeat;
}

.chatTab {
  border-right: 1px solid #e3f2ff;
  width: 70px;
}

.chatTab .nav>li:last-child {
  margin-bottom: 0;
}

.chatTab .nav>li button {
  background: none;
  border: none;
  font-size: 12px;
  opacity: .5;
  font-weight: 500;
  padding: 13px;
  white-space: nowrap;
  width: 100%;
}

.chatTab .nav>li button.active img {
  filter: inherit;
}

.chatTab .nav>li button img {
  filter: brightness(0.2);
  pointer-events: none;
}
.chatTab .nav>li button span{
  pointer-events: none;
}
.chatTab .nav>li button.active {
  opacity: 1;
  color: #ffffff;
  background-color: #00376a;
}

.tab-content {
  width: 100%;
}

.chatList {
  max-height: calc(100vh - 102px);
  overflow: hidden;
  overflow-y: auto;
}
.unread{
  font-weight: 700;
}
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
  background: #f1f1f1;
}

.chatList>li {
  display: block;
}

.chatList>li {
  border-bottom: 1px solid #e3f2ff;
}

.chatList>li>a {
  display: block;
  color: #000;
  padding: 13px 13px;
}

.chatList>li>a:hover,
.chatList>li>a.active {
  background: #ff850038;
}

.titleDiv {
  border-bottom: 1px solid #e3f2ff;
}

.titleDiv h6 {
  font-size: 13px;
  font-weight: 600;
  color: #000;
  padding: 13px;
  margin-bottom: 0;
}

.chat-profile img {
  width: 35px;
  height: 35px;
  border-radius: 100px;
}

.conntxt {
  padding-left: 10px;
}

.conntxt strong {
  font-size: 14px;
  line-height: 19px;
  display: block;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
}

.conntxt p {
  font-size: 12px;
  line-height: normal;
  margin: 2px 0 0;
  opacity: 0.6;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
}

.msgTimes {
  display: block;
  opacity: .6;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
}

.msgCount {
  display: inline-block;
  padding: 3px 6px 4px;
  font-size: 10px;
  background-color: #00376a;
  border-radius: 100px;
  line-height: 10px;
  color: #fff;
  font-weight: 600;
}

.chathead {
  padding: 10px 13px;
  border-bottom: 1px solid #e3f2ff;
}

.chatbody.chatrspnveBody {
  height: calc(100vh - 181px);
}
.chatbody {
  height: calc(100% - 95px);
  overflow: hidden;
  overflow-y: auto;
}

.chatFoot {
  padding: 13px;
  border-top: 1px solid #e3f2ff;
  background-color: #fff;
}


.arrowPrivious i {
  font-size: 30px;
  line-height: 31px;
  display: block;

}

.moreDropdown {
  box-shadow: 0 0 3px -1px;
}

.msgInputgroup {
  display: flex;
  gap: 20px;

}

.msgInputgroup .msgleft {
  flex: 1 0 0%;
  border-radius: 100px;
  background: #ececec;
  overflow: hidden;
}

/* .msgInputgroup .msgInput:hover{
  height:180px
} */
.chatFoot.chatFootGallery .msgInputgroup .msgleft{
  border-radius: 10px;
}
.chat-galleries.chatAddGlry {
  height: 100%;
  width: 100%;
}
.chat-galleries.chatAddGlry button input{
  width: 50px;
  position: absolute;
  left: 0;
  height: 50px;
  top: 0;
  opacity: 0;
}

.chat-galleries.chatAddGlry button{
  width: 40px;
  border-radius: 5px;
  position: relative;
  height: 100%;
  font-size: 25px;
  color: #000;
  margin: 5px 12px;
  height: 40px;
}

.chatModal.chattopGallery {
  height: calc(100% - 150px) !important;
}
.chat-galleries button{
  position: absolute;
  right: 0;
  background-color: #b1b1b1;
  border-radius: 30px;
  font-size: 12px;
  width: 21px;
  height: 21px;
  text-align: center;
  border: 0;
}
.chat-galleries{
  width: 100%;
  position: relative;
}
.chat-galleries img{
  width: 50px;
  margin: 10px;
}
.chat-galleries video{
  width: 50px;
  margin: 10px;
}
.chat-galleries audio{
  width: 227px;
  margin: 10px;
}
.chat-galleries embed{
  width: 100px;
  margin: 10px;
}
.msgInputgroup .form-control {
  height: 50px;
  border-radius: 0;
  background: #ececec;
  border: none;
  padding: 0 0 3px 50px;
  font-size: 14px;
  outline: none;
  box-shadow: none;
}


.emojitoggle {
  position: absolute;
  top: 11px;
  left: 12px;
  background: none;
  border: none;
}

.mstBtn {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: #ececec;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

.mstBtn img {
  display: block;
}

.sendBtn {
  background: #00376a;
}

.sendBtn img {
  filter: invert(1);
}

.sendBtn:disabled {
  background-color: #ececec !important;
}

.chats {
  padding: 13px 20px;
}

.chatModal {
  position: relative;
  /* display: none; */
}

.chatModal .arrowPrivious {
  background: none;
  box-shadow: none;
  outline: none;
  border: none;
  padding: 0;
}

.chatModal .modal-dialog {
  pointer-events: inherit;
  max-width: 100%;
  margin: 0;
}

.userimg {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.msgContent {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-bottom: 10px;
}

.msgtextGroup {
  max-width: 75%;
}

.msgText {
  background: #f5f5f5;
  padding: 10px;
  border-radius: 0px 7px 7px 7px;
  font-size: 14px;
  max-width: 400px;
}

.message-out {
  justify-content: flex-end;
  text-align: right;
}

.message-out .msgText {
  border-radius: 7px 0px 7px 7px;
  background: #00376a;
  color: #fff;
}

.msgTime {
  font-size: 10px;
}

.filterTab {
  margin-left: 20px;
  margin-right: auto;
}

.filterTab .nav-tabs {
  border: none;
}

.filterTab .nav-tabs .nav-item {
  margin: 0 5px;
}

.filterTab .nav-tabs .nav-link {
  padding: 0 4px;
  border: none;
  font-size: 13px;
  color: #222 !important;
  font-weight: 600;
  position: relative;
  background: none !important;
}

.filterTab .nav-tabs .nav-link:after {
  content: "";
  position: absolute;
  bottom: -18px;
  left: 0;
  right: 0;
  background: #00376a;
  width: 100%;
  height: 2px;
  opacity: 0;

}

.filterTab .nav-tabs .nav-link.active:after {
  opacity: 1;
}

.filterTab .nav-tabs .nav-link.active {
  color: #00376a !important;
}

.chatButton {
  border: none;
  z-index: 21;
  position: fixed;
  bottom: 20px;
  background: none;
  right: 20px;
  color: white;
  padding: 10px;
  border: none;
  outline: none !important;
}

.chatSection {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
}

.chatSection.open {
  opacity: 1;
  visibility: visible;
  z-index: 1500;
}

.minimize .logoLeft img {
  width: 80px;
}

.minimize .arrowPrivious {
  display: block;
}

.sizeToggle img {
  width: 17px;
  height: 17px;
}

button.sizeToggle.open img {
  display: none;
}

.createGroupButton {
  padding: 8px 20px;
  border-radius: 100px;
  background: #00376a !important;
  font-size: 12px;
  color: #fff;
  border: none;
  display: flex;
  align-items: center;
  gap: 6px;
}

.minimize .createGroupButton {
  display: none;
}

.minimize.chatSection {
  position: fixed;
  top: auto;
  bottom: 20px;
  right: 20px;
  width: 350px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .3);
  border-radius: 5px;
  height: calc(100% - 40px);
  z-index: 9999;
}

.chatMain {
  height: calc(100% - 50px);
}

.minimize .userleft {
  flex-wrap: wrap;
  width: 100%;
  flex-direction: column;
  height: 100%;
}

.minimize .chatTab .nav>li button img {
  height: 20px;
}


.minimize .chatRight {
  width: 100%;
}

.minimize .chatModal {
  width: 100%;
  position: absolute;
  top: 48px;
  left: 0;
  height: calc(100% - 48px);
  background: #fff;
}

.minimize .chatbody {
  height: calc(100% - 96px);
}

.minimize .chatTab {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.minimize .chatTab .nav {
  flex-direction: row !important;
}

.minimize .chatTab .nav li {
  flex: 1 0 0%;
  margin: 0;
}

.minimize .tab-content {
  order: 1;
  height: calc(100% - 68px);
}

.minimize .tab-content>.tab-pane {
  height: 100%;
}

.minimize .chatGroup {
  height: 100%;
}

.minimize .chatList {
  height: calc(100% - 50px);
  max-height: 100%;
}

.minimize .filterTab {
  display: none;
}

.minimize .userSearch {
  display: none;
}
.minimize .createPollToggle{display: none;}
.minimize .msgInputgroup .form-control{height: 40px;}
.minimize .emojitoggle {
  top: 5px;
  left: 9px;
}
.minimize .mstBtn{
  width: 40px;
  height: 40px;
}
button.mstBtn.sendBtn img{
  width: 25px;
  margin-left: -4px;
}
.minimize .mstBtn img{width: 15px;}
.minimize .msgInputgroup{gap: 7px;}
.sizeToggle {
  background: none;
  border: none;
  line-height: 15px;
}

.createGroup .modal-header {
  padding: 10px 15px;
}

.createGroup .modal-title {
  font-size: 16px;
  font-weight: 600;
}

.btn-close {
  outline: none;
  box-shadow: none !important;
}

.createGroup .modal-content {
  border: none;
}

.createInput label {
  font-size: 14px;
  font-weight: 600;
}

.createInput .form-control {
  height: 40px;
  border-radius: 0;
  font-size: 14px;
}

.groupButton {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.groupButton .btn {
  min-width: 100px;
  font-size: 14px;
  padding: 8px;
}

.groupButton .btn-primary {
  background: #ff8500;
  border-color: #ff8500;
}

.filesTabPanel .table tr th {
  padding: 12px 20px;
  font-size: 14px;
  font-weight: 600;
  background: #00376a;
  color: #fff;
  vertical-align: middle;
}


.filesTabPanel .table tr td {
  font-size: 14px;
  padding: 12px 20px;
  vertical-align: middle;
}

.cstmCheck {
  position: relative;
}

.cstmCheck input {
  position: absolute;
  opacity: 0;
}

.cstmCheck label {
  position: relative;
  display: block;
}

.cstmCheck label:after {
  content: "";
  width: 17px;
  height: 17px;
  border: 1px solid #ccc;
  display: block;
}

.cstmCheck input[type="checkbox"]:checked~label:after {
  content: '\F26E';
  font-family: bootstrap-icons !important;
  line-height: 15px;
  font-weight: normal;
  color: #00376a;
  font-size: 17px;
  border: 1px solid #00376a;
}

.table tr th .cstmCheck input[type="checkbox"]:checked~label:after {
  color: #fff;
  border: 1px solid #fff;
}

.photoList {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 10px;
  gap: 0;
}

.photoList>li {
  width: 25%;
  padding: 5px;
}

.photoList>li a {
  display: block;
}

.photoList>li a img {
  height: 150px;
  object-fit: cover;
}

.msgtimer {
  text-align: center;
  font-size: 12px;
  padding: 10px;
  opacity: 0.6;
}

.ChatPeopleone:hover .timeMassage {
  display: none;
}

.ChatPeopleone:hover .dropDownchat {
  display: block !important;
}

.dropDownchat {
  position: absolute;
  right: 0;
  top: 12px;
  /* display: none; */
}

.dropDownchat .dropdown-item {
  font-size: 14px;
  font-weight: 400;
}

.dropDownchat .dropdown-menu {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.dropDownchat {
  display: none;
}

.dropDownchat .dropdown-item {
  padding: 10px 14px;
}

.welcomeScreen {
  padding: 30px;
  height: calc(100vh - 60px);
  overflow: auto;
  background: #f5f5f5;
}



.profileMenshion {
  background-color: red;
  color: #fff;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  font-size: 14px;
  font-weight: 500;
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 19px;
  right: -5px;
}

.minimize .profileMain .profile-image {
  display: none;
}

.chatCloseToggle {
  border: none;
  background: none;
  font-size: 20px;
  padding: 0;
  color: #00376a;
  font-weight: 600;
  display: none;
}

.minimize .chatCloseToggle {
  display: block;
}
button.createGroupButton2 {
  background: none;
  border: none;
  margin-right: 15px;
}
.emoji-elem {
  position: absolute !important;
  bottom: 60px !important;
}
.overflow-auto {
  z-index: 99999;
}
.uploadimagemodal span {
  font-size: 14px;
  color: #7b7b7b;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 240px;
  display: inline-block !important;
}

.uploadimagemodal textarea label,
.uploadimagemodal textarea {
  font-size: 12px;
}

.uploadimagemodal {
  margin-bottom: 14px;
}

.uploadimagemodal p {
  font-size: 13px;
  color: #a49d9d;
  line-height: 8px;
}

.uploadimagemodal button i {
  font-size: 20px;
  color: #a1a1a1;
}

.modalimage {
  --bs-modal-width: 350px;
}

.uploadbtn {
  padding: 7px 22px;
  color: #8a8a8a;
  background-color: #f3f3f3;
  border-radius: 6px;
  border: 1px dashed #707070;
  font-size: 14px;
  position: relative;
  min-width: 100%;
  cursor: pointer;
  margin-bottom: 15px;
}

.uploadbtn img {
  background-color: #dcdcdc;
  height: 30px;
  width: 30px;
  padding: 5px;
  border-radius: 50%;
  border: 1px dashed #707070;

}

.heightcontent {
  max-height: 180px;
  overflow: auto;

}

.extensiondiv {
  display: inline-block;
  text-align: center;
  background-color: #0ac963;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  width: 2.7em;
  height: 2.6em;
  line-height: 2.4em;
  border-radius: 10%;
}

.overlap-modal {
  z-index: 9999 !important;
}