
/* * {
  margin: 0;
  padding: 0
} */

#heading {
  min-height: auto;
  background-color: #364c9d;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
  padding: 10px;
}

#msform {
  text-align: center;
  position: relative;
  margin-top: 0px;
  
}

#msform fieldset {
  background: white;
  border: 0 none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;
  position: relative
}

.form-card {
  text-align: left
}

#msform fieldset:not(:first-of-type) {
  display: none
}

#msform input,
#msform textarea {
  /* padding: 8px 15px 8px 15px;
  border: 1px solid #ccc;
  border-radius: 0px;
  margin-bottom: 25px;
  margin-top: 2px;
  width: 100%;
  min-height: 50px;
  box-sizing: border-box;
  font-family: montserrat;
  color: #2C3E50;
  background-color: #ECEFF1;
  font-size: 16px;
  letter-spacing: 1px */
}

#msform input:focus,
#msform textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #673AB7;
  outline-width: 0
}

#msform .action-button {
  width: 100px;
  min-height: 30px;
  background: #673AB7;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 10px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 0px 10px 5px;
  float: right
}

#msform .action-button:hover,
#msform .action-button:focus {
  background-color: #311B92
}

#msform .action-button-previous {
  width: 100px;
  min-height: 30px;
  background: #616161;
  font-weight: bold;
  color: white;	
  border: 0 none;
  border-radius: 10px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px 10px 0px;
  float: right
}

#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
  background-color: #000000;
}

.card {
  /* z-index: 0; */
  border: none;
  position: relative;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 30px;
  /* border-radius: 10px; */
}

.fs-title {
  font-size: 25px;
  color: #673AB7;
  margin-bottom: 15px;
  font-weight: normal;
  text-align: left;
}

.purple-text {
  color: #673AB7;
  font-weight: normal;
}

.steps {
  font-size: 25px;
  color: gray;
  margin-bottom: 10px;
  font-weight: normal;
  text-align: right
}

.fieldlabels {
  color: #575353;
  font-size: x-large;
  text-align: left;
}

#progressbar1 {
  margin-bottom: 30px;
  overflow: hidden;
  color: lightgrey
}

#progressbar1 .active {
  color: #364c9d;
}

#progressbar1 li {
  list-style-type: none;
  font-size: 15px;
  width: 16%;
  float: left;
  position: relative;
  font-weight: 400
}

#progressbar1 #employee:before {
  font-family: FontAwesome;
  content: "\f058";
}
#progressbar1 #manager:before {
  font-family: FontAwesome;
  content: "\f058";
}


#progressbar1 #it:before {
  font-family: FontAwesome;
  content: "\f00d";
}

#progressbar1 #admin:before {
  font-family: FontAwesome;
  content: "\f058";
}

#progressbar1 #finance:before {
  font-family: FontAwesome;
  content: "\f00d";
}
#progressbar1 #it:before {
  font-family: FontAwesome;
  content: "\f00d";
}


#progressbar1 li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px
}

#progressbar1 li:after {
  content: '';
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1
}

#progressbar1 li.active:before,
#progressbar1 li.active:after {
  background: #364c9d;
}

.progress {
  height: 20px
}

.progress-bar {
  background-color: #673AB7
}

.fit-image {
  width: 100%;
  object-fit: cover
}