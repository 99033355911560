.rating_bg{
    background-image: url(
        "");
}


/* .emoji_hover:hover{
    transform: scale(1.5);
} */

.line_rating{
    border: 3px solid; 
    border-image-slice: 1;
    border-bottom: 5px;
    border-image-source: linear-gradient(to left, #743ad5, #FF0057);
}


@-webkit-keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
  }
  .rating_bg {
    position: relative;
    z-index: 0;
    /* width: 400px;
    height: 300px; */
    border-radius: 10px;
    overflow: hidden;
    padding: 2rem;
  }
  .rating_bg::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #399953;
    background-repeat: no-repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(#399953, #399953), linear-gradient(#fbb300, #fbb300), linear-gradient(#d53e33, #d53e33), linear-gradient(#377af5, #377af5);
    -webkit-animation: rotate 4s linear infinite;
            animation: rotate 4s linear infinite;
  }
  .rating_bg::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    background: white;
    border-radius: 5px;

  }